import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../layout/BasicPage'
import Link from '../../components/Link'
import SEO from '../../components/SEO'

const Research = ({ data, location }) => {
	const { research } = data.allFile

	return (
		<Layout width='narrow'>
			<SEO title='Research' href={location.href} />
			<Layout.Header title='Research' hero='scatter' />
			<ul>
				{research &&
					research.map(({ result }) => {
						return (
							<ResearchArticle
								title={result.article.data.title}
								date={result.article.data.date}
								description={result.article.data.description}
								to={`${result.link.replace(/\.md$/, '')}`}
							/>
						)
					})}
			</ul>
		</Layout>
	)
}

export default Research

const ResearchArticle = ({ title, date, description, to }) => {
	return (
		<div className='research-article'>
			<Link to={to}>{title}</Link>
			<div className='research-article--summary'>{description}</div>
		</div>
	)
}

export const query = graphql`
	query ResearchQuery {
		allFile(
			filter: {
				sourceInstanceName: { eq: "research" }
				extension: { eq: "md" }
			}
		) {
			research: edges {
				result: node {
					link: relativePath
					article: childMarkdownRemark {
						data: frontmatter {
							date(formatString: "yyyy/MM/DD")
							description
							title
						}
					}
				}
			}
		}
	}
`
